$primary-color : #033059;
$secondary-color: #0558a3;

$thin: 100;
$light: 300;
$normal: 400;
$medium : 500;
$bold: 700;
$black: 900;

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}