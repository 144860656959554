@import "styles/config.scss";

.login :global {

  display: flex;
  align-items: center;
  height: inherit;
  min-height: inherit;
  justify-content: center;
  .content {

    margin-top: -200px;
    width: 400px;

    .heading {
      background:$primary-color;
      .title {
        padding:30px 40px 15px 40px;
        color:white;
        font-size:18px
      }
    }

    form {

      background: white;
      padding: 30px 40px;

      .password {
        margin: 20px 0;
      }
      .button {
        text-align:right;
      }
    }
  }


}
