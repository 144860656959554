@import "nProgress/nProgress";
@import "config";

html {
  min-height: 100vh;
  font-size: 16px;
}

body {
  background: #EEEEEE !important;
  min-height: inherit;
  scroll-behavior: smooth;
}

pre {
  font-size: 10px;
}

#__next {
  min-height: inherit
}

.logo {
  text-align: center;
  padding-top: 10px;
  background: $primary-color
}

.app {
  display: flex
}

.smallText {
  color:#666666;
}

.footer {
  top:auto;
  bottom:0;
  background:white;
}
/* -- HELPERS -- */

.pulled-right {
  float: right
}

/* MATERIAL */
.MuiAlert-message {
  font-weight: normal !important;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: $primary-color;
  border-radius: 50%;
  display: inline-block;
  &.outlined {
    border:1px solid $primary-color;
    background:transparent;
  }
}

ul.menu {
  .MuiListItemIcon-root {
    min-width: 34px;
    color: $primary-color;
  }
  .MuiListItem-root {
    padding: 3px 16px;
    &:hover {
      text-decoration: none;
    }
    &.active {
      background: $primary-color;
      color: white;
      &:hover span {
        color: white;
      }
      .MuiListItemIcon-root {
        color: inherit;
      }
    }

  }
  > a:hover {
    div {
      color: $secondary-color
    }
  }
}

.fadeInBounce {
  animation-duration: 1.2s;
  animation-name: fadeInBounce;
}


@keyframes fadeInBounce {
  0% { opacity:0 }
  30% { opacity:0.7; }
  72% { opacity:0.3; }
  100% { opacity:1; }
}



.__aligned_center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.popper {
  &.hidden {
    visibility:hidden;
  }
  z-index: 2;
  .palet, .barrel {
    .bulkWeightTotalPopper {

      display:inline-block;
      width:80px;
      vertical-align:baseline;
      padding-left:5px;
      .MuiInput-underline::after {
        display:none!important;
      }

    }
    span.label {width:80px;display:inline-block}
  }
  > div {
    z-index:1;
    &:after {
      content: "";
      width: 20px;
      height: 20px;
      transform: rotate(-45deg);
      background: #fff;
      position: absolute;
      z-index: -1;
      top: -8px;
      left: calc(50% - 10px);
    }
  }

}

.MuiButton-root {
  &.hovered {
    background-color:#0558a3
  }
}

/* -- GOOGLE CHART -- */
.pie {
  svg > g > g:last-child {
    pointer-events: none
  }
}
